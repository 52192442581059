Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.applicationJsonContentType = "application/json";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.surveyApiEndPoint = "bx_block_surveys/surveys"
exports.surveyQuestionApiEndPoint = "bx_block_surveys/questions"
exports.surveyQuestionSubmissionsApiEndPoint = "bx_block_surveys/submissions"
// Customizable Area End